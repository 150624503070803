<template>
	<div>
		<h3>绑定支付宝小程序</h3>
		<el-form ref="create_form" label-width="100px" style="margin-top: 20px;" :model="create_form" :rules="create_rules" :size="theme.size" status-icon>
			<el-form-item label="应用名称" prop="name">
				<el-input placeholder="请输入应用名称" autocomplete="off" v-model="create_form.name" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用ID" prop="app_id">
				<el-input placeholder="请输入应用ID" autocomplete="off" v-model="create_form.app_id" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用私钥" prop="rsa_private_key">
				<el-input placeholder="请输入应用私钥" autocomplete="off" v-model="create_form.rsa_private_key" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="支付宝公钥" prop="alipay_rsa_public_key">
				<el-input placeholder="请输入支付宝公钥" autocomplete="off" v-model="create_form.alipay_rsa_public_key" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
		},
		data() {
			return {
				create_form: {},
				create_rules: {
					name: [{ required: true, message: '必须填写应用名称', trigger: 'blur' }],
					app_id: [{ required: true, message: '必须填写应用ID', trigger: 'blur' }],
					rsa_private_key: [{ required: true, message: '必须填写应用私钥', trigger: 'blur' }],
					alipay_rsa_public_key: [{ required: true, message: '必须填写支付宝公钥', trigger: 'blur' }]
				}
			}
		},
		created () {
		}
	};
</script>