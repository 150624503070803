<template>
	<div>
		<el-tabs :tab-position="theme.width > 980 ? 'left' : 'top'" :style="{height: (theme.height - 160)+'px'}" v-model="activeTab">
			<el-tab-pane label="基本信息" name="basic">
				<div style="display: flex;">
					<div style="padding: 10px 20px;">
						<el-avatar shape="square" :size="80" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
					</div>
					<el-descriptions :column="2" :title="'No.'+shop.number">
						<el-descriptions-item label="名称">{{shop.name}}</el-descriptions-item>
						<el-descriptions-item label="认证品牌">{{shop.brand?shop.brand.name:'去认证'}}</el-descriptions-item>
						<el-descriptions-item label="主体信息"><el-button type="text" size="mini">去认证</el-button></el-descriptions-item>
						<el-descriptions-item label="关联商圈">{{shop.market?shop.market.name:'去关联'}}</el-descriptions-item>
						<el-descriptions-item label="创建人">{{shop.user.name}}</el-descriptions-item>
						<el-descriptions-item label="创建时间">{{shop.created_at}}</el-descriptions-item>
						<el-descriptions-item label="关联商品">{{shop.goods_count}}</el-descriptions-item>
						<el-descriptions-item label="关联用户">{{shop.wallets_count}}</el-descriptions-item>
					</el-descriptions>
				</div>
			</el-tab-pane>
			<el-tab-pane label="店铺设置" name="shop">
				<el-form ref="shop_form" label-width="240px" :model="shop_setting" :size="theme.size" status-icon>
					<el-form-item label="经营状态" prop="opening">
						<el-switch active-text="营业" inactive-text="休息" v-model="shop_setting.opening" :active-value="true" :inactive-value="false"></el-switch>
						<p style="color: #999;">设置休息后，买家将无法在店内消费，请谨慎操作。</p>
					</el-form-item>
					<el-form-item label="营业时间" prop="opening_hours_type">
						<el-radio-group v-model="shop_setting.opening_hours_type" @change="shop_setting.opening_hours_time = []">
							<el-radio label="everyday">每天重复</el-radio>
							<el-radio label="weekly">每周重复</el-radio>
						</el-radio-group>
						<p style="color: #999;">在你设置的营业时间内，买家可以在店内消费。</p>
					</el-form-item>
					<el-form-item prop="opening_hours_time" v-if="shop_setting.opening_hours_type == 'everyday'">
						<div v-for="(time, t) in shop_setting.opening_hours_time" :key="t">
							<el-time-picker range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" value-format="HH:mm" style="margin-bottom: 10px;" v-model="shop_setting.opening_hours_time[t]" :picker-options="{format: 'HH:mm'}" is-range></el-time-picker>
							<el-button type="text" style="margin-left: 10px;" @click="removeTime(time)">删除时间</el-button>
						</div>
						<el-button type="text" v-if="shop_setting.opening_hours_time.length < 3" @click="addTime">添加时间</el-button>
					</el-form-item>
					<el-form-item prop="opening_hours_time" v-if="shop_setting.opening_hours_type == 'weekly'">
						<div>
							<el-checkbox-group v-model="weekly_form">
								<el-checkbox v-for="(week, w) in weekly" :key="w" :label="w">{{week}}</el-checkbox>
							</el-checkbox-group>
							<el-button type="text" @click="addWeek">提交创建</el-button>
						</div>
						<div v-for="(week, w) in shop_setting.opening_hours_time" :key="w">
							<div>{{week.weekly.map((i) => { return weekly[i] }).join('、')}} <el-button type="text" style="margin-left: 10px;" @click="removeTime(week)">删除</el-button></div>
							<div style="margin-top: 10px;" v-for="(time, t) in shop_setting.opening_hours_time[w].time" :key="t">
								<el-time-picker range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" value-format="HH:mm" style="margin-bottom: 10px;" v-model="shop_setting.opening_hours_time[w].time[t]" :picker-options="{format: 'HH:mm'}" is-range></el-time-picker>
								<el-button type="text" style="margin-left: 10px;" @click="removeWeekTime(w, time)">删除时间</el-button>
							</div>
							<el-button type="text" v-if="shop_setting.opening_hours_time[w].time.length < 3" @click="() => { shop_setting.opening_hours_time[w].time.push([]) }">添加时间</el-button>
						</div>
					</el-form-item>
					<el-form-item label="购物车" prop="shopping_cart">
						<el-switch active-text="开启" inactive-text="关闭" v-model="shop_setting.shopping_cart" :active-value="true" :inactive-value="false" :disabled="!shop_setting.buy_now_btn"></el-switch>
						<p style="color: #999;">关闭购物车后，商品仅支持单独购买并结算，请谨慎操作。</p>
					</el-form-item>
					<el-form-item label="店铺首页置灰" prop="home_ash">
						<el-switch active-text="置灰" inactive-text="不置灰" v-model="shop_setting.home_ash" :active-value="true" :inactive-value="false"></el-switch>
						<p style="color: #999;">一旦修改，立即生效。</p>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('shop_form')">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="商品详情" name="goods">
				<el-form ref="goods_form" label-width="240px" :model="goods_details" :size="theme.size" status-icon>
					<el-form-item label="商详页店铺按钮" prop="shop_button">
						<el-switch active-text="开启" inactive-text="关闭" v-model="goods_details.shop_button" :active-value="true" :inactive-value="false"></el-switch>
						<p style="color: #999;">开启展示后，买家可以点击该按钮快速回到店铺主页。</p>
					</el-form-item>
					<el-form-item label="商详页立即购买" prop="buy_now_btn">
						<el-switch active-text="开启" inactive-text="关闭" v-model="goods_details.buy_now_btn" :active-value="true" :inactive-value="false" :disabled="!goods_details.shopping_cart"></el-switch>
						<p style="color: #999;">设置选择不展示，商品详情页仅展示加入购物车，不展示立即购买按钮。</p>
					</el-form-item>
					<el-form-item prop="buy_now_btn_text" v-if="goods_details.buy_now_btn">
						<el-input placeholder="默认名称为“立即购买”，可自定义名称，支持中英文混合。如：马上抢购" style="width: 520px;" autocomplete="off" maxlength="6" v-model="goods_details.buy_now_btn_text" show-word-limit clearable></el-input>
						<p style="color: #999; line-height: 1; padding-top: 10px;">自定义后，全店商品详情页名称都将更改，若需要设置单个商品的立即购买按钮，可前往商品管理页单独编辑相关的商品。</p>
					</el-form-item>
					<el-form-item label="商详页销量" prop="sales_volume">
						<el-switch active-text="开启" inactive-text="关闭" v-model="goods_details.sales_volume" :active-value="true" :inactive-value="false"></el-switch>
						<p style="color: #999;">选择展示，商品详情页中将展示当前商品的销量。</p>
					</el-form-item>
					<el-form-item prop="sales_volume_gte" v-if="goods_details.sales_volume">
						<el-input placeholder="请输入数值。默认大于等于1" style="width: 400px;" v-model="goods_details.sales_volume_gte">
							<template slot="prepend">销量达到</template>
							<template slot="append">件时显示</template>
						</el-input>
					</el-form-item>
					<el-form-item label="商详页购买记录" prop="purchase_records">
						<el-switch active-text="开启" inactive-text="关闭" v-model="goods_details.purchase_records" :active-value="true" :inactive-value="false"></el-switch>
						<p style="color: #999;">选择展示，商品详情页中将展示当前商品的购买记录。</p>
					</el-form-item>
					<el-form-item prop="purchase_records_gte" v-if="goods_details.purchase_records">
						<el-input placeholder="请输入数值。默认大于等于1" style="width: 440px;" v-model="goods_details.purchase_records_gte">
							<template slot="prepend">购买记录达到</template>
							<template slot="append">人时展示</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="订单交易" name="order">
				<el-form ref="order_form" label-width="240px" :model="order_deal" :size="theme.size" status-icon>
					<el-form-item label="默认库存扣减方式" prop="deduction_method">
						<el-radio-group v-model="order_deal.deduction_method">
							<el-radio label="now">拍下减库存</el-radio>
							<el-radio label="pay">付款减库存</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="付款减库存预占时间" prop="payment_and_inventory_preoccupancy_time">
						<el-input placeholder="请输入数值。默认60分钟" style="width: 620px;" v-model="order_deal.payment_and_inventory_preoccupancy_time">
							<template slot="prepend">拍下后</template>
							<template slot="append">分钟内未付款，库存自动释放，方便其他消费者下单</template>
						</el-input>
						<p style="color: #999;">注：当拍下的商品库存预占超时后，商品的库存会返还，但不影响订单状态。付款减库存预占时间范围为1-10分钟。</p>
					</el-form-item>
					<el-form-item label="待付款订单取消时间" prop="order_cancellation_time_pending_payment">
						<el-input placeholder="请输入数值。默认60分钟" style="width: 560px;" v-model="order_deal.order_cancellation_time_pending_payment">
							<template slot="prepend">拍下未付款订单</template>
							<template slot="append">分钟内未付款，自动取消订单</template>
						</el-input>
					</el-form-item>
					<el-form-item label="发货后自动确认收货时间" prop="automatically_confirm_receipt_time_after_delivery">
						<el-input placeholder="请输入数值。默认7天" style="width: 420px;" v-model="order_deal.automatically_confirm_receipt_time_after_delivery">
							<template slot="prepend">发货后</template>
							<template slot="append">天，自动确认收货</template>
						</el-input>
						<p style="color: #999; line-height: 1; padding-top: 10px;">修改后将对新产生的订单立即生效，已产生的订单自动确认收货时间将在以发货时的设置为准。<br>从2022年10月18日起，同城配送订单和上门自提订单自动确认收货时间，根据不同情况调整为24小时或48小时，与商家配置无关。</p>
					</el-form-item>
					<el-form-item label="买家申请售后限制" prop="buyers_apply_for_after_sale_restrictions">
						<el-input placeholder="请输入数值。默认15天" style="width: 500px;" v-model="order_deal.buyers_apply_for_after_sale_restrictions">
							<template slot="prepend">买家确认收货</template>
							<template slot="append">天后，不支持买家申请售后</template>
						</el-input>
					</el-form-item>
					<el-form-item label="买家退货后商家自动确认收货时间" prop="returned_auto_confirm_at">
						<el-input placeholder="请输入数值。默认7天" style="width: 440px;" v-model="order_deal.returned_auto_confirm_at">
							<template slot="prepend">买家发货后</template>
							<template slot="append">天，自动确认收货</template>
						</el-input>
					</el-form-item>
					<el-form-item label="买家留言提示文案" prop="message_prompt">
						<el-input placeholder="默认文案为“留言建议提前协商（250字以内）”，可自定义文案。如：请填写所在小区名称" style="width: 620px;" autocomplete="off" maxlength="28" v-model="order_deal.message_prompt" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item label="收货人姓名校验" prop="consignee_name_check">
						<el-switch active-text="开启" inactive-text="关闭" v-model="order_deal.consignee_name_check" :active-value="true" :inactive-value="false"></el-switch>
						<p style="color: #999; line-height: 1; padding-top: 10px;">应国家邮政总局的要求，个人快递包裹必须收货人实名制。<br>开启配置后收货人姓名含小姐，女士，先生，男士”等模糊字段的都无法下单。</p>
					</el-form-item>
					<el-form-item>
						<el-button type="primary">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="商品主页" name="mall">
				<el-form ref="mall_form" label-width="240px" :size="theme.size" status-icon>
					<el-form-item label="联系人" prop="attn">
						<el-input placeholder="请输入联系人" autocomplete="off" maxlength="8" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="内容主页" name="content">
				<el-form ref="content_form" label-width="240px" :size="theme.size" status-icon>
					<el-form-item label="联系人" prop="attn">
						<el-input placeholder="请输入联系人" autocomplete="off" maxlength="8" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="个人中心" name="person">
				<el-form ref="person_form" label-width="240px" :size="theme.size" status-icon>
					<el-form-item label="联系人" prop="attn">
						<el-input placeholder="请输入联系人" autocomplete="off" maxlength="8" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="微信小程序" name="wechat">
				<we-app :create-type.sync="createType"></we-app>
			</el-tab-pane>
			<el-tab-pane label="抖音小程序" name="toutiao">
				<tt-app></tt-app>
			</el-tab-pane>
			<el-tab-pane label="百度小程序" name="baidu">
				<bd-app></bd-app>
			</el-tab-pane>
			<el-tab-pane label="小红书小程序" name="xhs">
				<xhs-app></xhs-app>
			</el-tab-pane>
			<el-tab-pane label="支付宝小程序" name="alipay">
				<ali-app></ali-app>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	import weApp from './weapp';
	import ttApp from './ttapp';
	import bdApp from './bdapp';
	import xhsApp from './xhsapp';
	import aliApp from './aliapp';
	
	export default {
		components: {
			weApp,
			ttApp,
			bdApp,
			xhsApp,
			aliApp
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			submitForm () {
			},
			async postConfigurations () {
				const res = await this.$http.post(this.$api.URI_CONFIGURATIONS, this.goods_details);
				console.log(res)
			},
			addWeek () {
				var disabled = [];
				var time = this.shop_setting.opening_hours_time || [];
				time.map((w) => { w.weekly.map((i) => { disabled.push(i) }) });
				var weekly = this.weekly_form.filter(item => !new Set(disabled).has(item));
				if (weekly.length > 0) {
					time.push({
						'weekly': weekly,
						'time': []
					});
					this.shop_setting = { ...this.shop_setting, opening_hours_time: time }
				}
			},
			addTime () {
				var time = this.shop_setting.opening_hours_time || [];
				time.push([]);
				this.shop_setting = { ...this.shop_setting, opening_hours_time: time }
			},
			removeTime (i) {
				var index = this.shop_setting.opening_hours_time.indexOf(i);
				if (index !== -1) {
					this.shop_setting.opening_hours_time.splice(index, 1);
				}
			},
			removeWeekTime (w, i) {
				var index = this.shop_setting.opening_hours_time[w].time.indexOf(i);
				if (index !== -1) {
					this.shop_setting.opening_hours_time[w].time.splice(index, 1);
				}
			}
		},
		data() {
			return {
				shop: null,
				activeTab: 'basic',
				createType: null,
				order_deal: {},
				shop_setting: {},
				goods_details: {},
				weekly_form: ['1'],
				weekly: {1: '周一', 2: '周二', 3: '周三', 4: '周四', 5: '周五', 6: '周六', 7: '周日'}
			}
		},
		async created () {
			let { query } = this.$route;
			this.createType = query.createType;
			const res = await this.$http.get(this.$api.URI_SHOPS, {params: {action: 'show', shop: query.no}});
			const { result } = res.data;
			this.shop = result;
			result.configurations.map((c) => {
				this[c.type] = {...c.extra};
			});
		}
	};
</script>