<template>
	<div>
		<h3>创建发布小程序</h3>
		<el-steps style="padding: 20px;" direction="vertical" :active="1">
			<el-step title="获取小红书小程序授权资格">
				<div slot="description" style="width: 100%; margin-bottom: 50px; border: 1px solid #f1f1f1;">
					<div style="display: flex; padding: 20px; align-items: center; justify-content: space-between; border-bottom: 1px solid #f1f1f1;">
						<div>
							<h5>完成店铺主体认证</h5>
							<p>仅支持主体认证类型为企业、政府及事业单位、其他组织类型的商家入驻。主体认证教程</p>
						</div>
						<el-button style="margin-top: 20px;" type="primary" :size="theme.size">去认证</el-button>
					</div>
					<div style="display: flex; padding: 20px; align-items: center; justify-content: space-between;">
						<div>
							<h5>完成店铺品牌授权</h5>
							<p>品牌授权类型仅支持旗舰店和专卖店商家入驻；品牌授权级别仅支持自有品牌和一级授权入驻。品牌授权教程</p>
						</div>
						<el-button style="margin-top: 20px;" type="primary" :size="theme.size">去认证</el-button>
					</div>
				</div>
			</el-step>
			<el-step title="已注册小红书小程序，立即授权发布">
				<div slot="description" style="display: flex; align-items: flex-start; justify-content: space-between;">
					<p>使用小红书专业号管理员账号扫码进行授权。<br>如未注册小程序，可以直接点击【专业号平台注册小程序】前注册企业主体的小程序账号；注：小红书专业号企业主体需要与店铺主体一致。<br><a href="https://pro.xiaohongshu.com/mini/shop?invitation_app_id=616e856cba1dc500014528fa&invitation_code=23bf7c594bd844afa05fef643f4e18f4">专业号平台注册小程序</a></p>
					<el-button style="margin-top: 20px;" type="primary" :size="theme.size">立即授权</el-button>
				</div>
			</el-step>
		</el-steps>
	</div>
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	
	export default {
		props: {
			createType: {
				type: String,
				default: null
			}
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async createPreauth (n) {
				const p = {action: 'createPreauth', appid: 'wxd784626b3f1e9f2a'}
				const res = await this.$http.post(this.$api.URI_WECHAT_COMPONENT, p);
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				const redirect_uri = encodeURIComponent("http://sa.dajing.ren/authorizer?number="+n);
				window.location.href = 'https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid='+p.appid+'&pre_auth_code='+result+'&redirect_uri='+redirect_uri+'&auth_type=3';
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
				})
			},
			resetForm (ref) {
				this.create_dialog = false;
				this.$refs[ref].resetFields();
				this.create_form = {
					code_type: 1
				}
			},
		},
		data() {
			var validEnterpriseCode = (rule, value, callback) => {
				if (!value) return callback(new Error('必须输入企业代码'));
				if (this.create_form.code_type == 1 && this.create_form.code.length != 18) return callback(new Error('输入企业代码长度必须为18位'));
				if (this.create_form.code_type == 2 && this.create_form.code.length != 9) return callback(new Error('输入企业代码长度必须为9位。例：xxxxxxxx-x'));
				if (this.create_form.code_type == 3 && this.create_form.code.length != 15) return callback(new Error('输入企业代码长度必须为15位'));
			}
			return {
				// createType: null,
				dialogVisible: false,
				create_dialog: false,
				create_form: {
					code_type: 1
				},
				create_rules: {
					enterprise_name: [{ required: true, message: '必须输入企业名', trigger: 'blur' }],
					code_type: [{ required: true, message: '必须选择企业代码类型', trigger: 'blur' }],
					code: [{ required: true, message: '必须输入企业代码', trigger: 'blur' }, { validator: validEnterpriseCode, trigger: 'blur' }],
					legal_persona_wechat: [{ required: true, message: '必须输入法人小红书号', trigger: 'blur' }],
					legal_persona_name: [{ required: true, message: '必须输入法人姓名', trigger: 'blur' }],
					component_phone: [{ required: true, message: '必须输入联系电话', trigger: 'blur' }],
					legal_persona_idcard: [{ required: true, message: '必须输入法人身份证号码', trigger: 'blur' }]
				}
			}
		},
		created () {
			// let { query } = this.$route;
			// this.createType = query.createType;
		}
	};
</script>